declare const window: Window & { dataLayer: Record<string, unknown>[] };

export default class GTMService {
  protected isProd = false;
  protected alreadyTrackedEvts: Set<string> = new Set<string>();

  constructor() {
    this.isProd = !(window.location.hostname.indexOf("localhost") > -1);
  }

  public async track(evtName: string, evt: object): Promise<void> {
    window.dataLayer = window.dataLayer || [];

    const event = {
      event: evtName,
    };

    Object.assign(event, evt);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer.push(event);
  }

  /**
   * Will discard all tracking requests if an event with evtName already tracked during the session. This protection
   * will NOT have effect on tracking done directly via track method.
   * @param evtName
   * @param evt
   */
  public async trackOncePerSession(evtName: string, evt: object): Promise<void> {
    // Load mem of already tracked evts
    this.alreadyTrackedEvts = new Set(JSON.parse(window.sessionStorage.getItem("gtmService.alreadyTrackedEvts")));
    if (this.alreadyTrackedEvts.has(evtName)) {
      return; // Evt with same key already tracked
    }
    // Log the event
    this.alreadyTrackedEvts.add(evtName);
    window.sessionStorage.setItem("gtmService.alreadyTrackedEvts", JSON.stringify(Array.from(this.alreadyTrackedEvts)));
    // Track
    return this.track(evtName, evt);
  }

  /**
   * Tracked once per (session, idadr). Tracked once per session - but if idadr changes, track anyway.
   */
  public async trackCoverageQuery(idadr: string, evt: object): Promise<void> {
    const lastTrackedIdadr = window.sessionStorage.getItem("gtmService.trackCoverageQuery.idadr");
    if (lastTrackedIdadr === null || parseInt(lastTrackedIdadr) !== parseInt(idadr)) {
      window.sessionStorage.setItem("gtmService.trackCoverageQuery.idadr", idadr);
      return this.track("coverage_query", evt);
    }
  }
}
