export class SimpleTimer {
  secondsElapsed: number;

  constructor(callback: () => void | (() => Promise<void>)) {
    this.setSecondsElapsed(0);

    this.tick(callback);
  }

  async tick(callback: () => void): Promise<void> {
    callback();

    this.secondsElapsed++;

    setTimeout(() => {
      this.tick(callback);
    }, 1000);
  }

  public getSecondsElapsed(): number {
    return this.secondsElapsed;
  }

  public setSecondsElapsed(secondsElapsed: number): void {
    this.secondsElapsed = secondsElapsed;
  }
}

export interface phoneNumberEmitType {
  is_valid: boolean;
  phone_code: string;
  phone_number: string;
}

export interface CountryDropdownItem {
  country_code: string;
  name: string;
  phone_code: string;
  regex: string;
}

export enum Audience {
  All,
  Customers,
  NonCustomers,
}
