import { MainModelService } from "@/services/main.model.service";
import type { UtmService } from "@/services/utm.service";
import type { StorageTagger } from "@/services/storageTagger.service";
import type { Tariff } from "@/services/coverage/Tariff";
import type CoverageService from "@/services/coverage/coverage.service";
import type AddressService from "@/services/address/address.service";

export class TariffsService extends MainModelService {
  tariffs: [Tariff[], Tariff[]] = [[], []];
  selectedTabIndex: number = null;
  selectedTariffIndex: number = null;
  tariffInfoShowed = false;
  loaded = false;
  isCompanyTariff = false;

  protected utmService: UtmService;
  protected localStorageTagger: StorageTagger;
  protected sessionStorageTagger: StorageTagger;
  protected coverageService: CoverageService;

  protected addressService: AddressService;

  constructor(
    utmService: UtmService,
    localStorageTagger: StorageTagger,
    sessionStorageTagger: StorageTagger,
    coverageService: CoverageService,
    addressService: AddressService
  ) {
    super();
    this.utmService = utmService;
    this.localStorageTagger = localStorageTagger;
    this.sessionStorageTagger = sessionStorageTagger;
    this.coverageService = coverageService;
    this.addressService = addressService;
  }

  hasTariffs() {
    return this.tariffs[0]?.length || this.tariffs[1]?.length;
  }

  loadTariffs(callback?: () => void) {
    if (this.addressService?.address?.idadr) {
      this.loaded = false;
      this.coverageService.setAddressAndGetTariffs(this.addressService?.address).then((response) => {
        const array: [Tariff[], Tariff[]] = [[], []];
        if (response?.result?.length) {
          for (const tariff of response.result) {
            if (!isNaN(Number(tariff.kc_pausal))) {
              tariff.kc_pausal = Math.round(parseFloat(tariff.kc_pausal)).toString();
            }
            const speed = tariff.param_net.split("/");
            tariff.speedCity = speed[0];
            tariff.speedVillage = speed[1];
            array[tariff?.param_tv ? 0 : 1].push(tariff);
          }
        }

        this.tariffs = array;

        if (!this.checkIsCompanyTariff()) {
          this.selectedTabIndex = 0;
          this.selectedTariffIndex = 0;
        } else {
          this.selectedTabIndex = 1;
          this.selectedTariffIndex = 0;
          this.tariffInfoShowed = true;
        }
        this.loaded = true;

        if (callback) {
          callback();
        }

        if (!this.hasTariffs()) {
          window.location.href = "/pro-kazdeho-neco/";
        }
      });
    } else {
      if (callback) {
        callback();
      }
    }
  }

  toggleTariffInfo() {
    this.tariffInfoShowed = !this.tariffInfoShowed;
  }

  changeTab(index: number) {
    if (!this.isCompanyTariff) {
      this.tariffInfoShowed = false;
    } else {
      this.tariffInfoShowed = true;
    }
    this.selectedTabIndex = index;
    const promoTariffIndex = this.tariffs[index].findIndex((i) => i.tarif_order_fav);
    this.selectedTariffIndex = promoTariffIndex > -1 ? promoTariffIndex : 0;
    const tvBlock = document.querySelector(".tv-channels") as HTMLElement;
    const connectionSteps = document.querySelector(".connection-steps") as HTMLElement;
    const faqBlock = document.querySelector(".faq-block") as HTMLElement;
    if (tvBlock) {
      tvBlock.style.display = index === 0 && this.tariffs?.[0]?.length ? "block" : "none";
      connectionSteps.style.display = this.tariffs?.[0]?.length ? "block" : "none";
      faqBlock.style.display = this.tariffs?.[0]?.length ? "block" : "none";
    }
  }

  checkIsCompanyTariff() {
    let companyTariffFound = false;

    this.tariffs.forEach((tariff) => {
      tariff.forEach((t) => {
        if (t.typ_nabidky === "firmy") {
          companyTariffFound = true;
          this.isCompanyTariff = true;
          return true;
        }
      });
    });

    if (!companyTariffFound) {
      this.isCompanyTariff = false;
      return false;
    }
  }

  changeTariff(index: number) {
    if (this.selectedTariffIndex !== index) {
      this.tariffInfoShowed = false;
      this.selectedTariffIndex = index;
    }
  }

  /**
   * Returns currently selected tariff
   */
  public getTariff(): Tariff | null {
    if (this.tariffs && this.selectedTabIndex !== null && this.selectedTariffIndex !== null) {
      return this.tariffs[this.selectedTabIndex][this.selectedTariffIndex];
    } else {
      return null;
    }
  }
}
