import { load } from "recaptcha-v3";

export default class RecaptchaService {
  protected siteKey: string;

  constructor(siteKey: string) {
    this.siteKey = siteKey;
  }

  public async getToken(action: string): Promise<string> {
    const recaptcha = await load(this.siteKey);
    return recaptcha.execute(action);
  }
}
