export type UtmDto = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  utm_adgroup?: string;
  utm_device?: string;
  utm_campaignid?: string;
  utm_adgroupid?: string;
};

export class UtmService {
  static SESSION_STORAGE_KEY = "poda_utm_bag";
  static UTM_PARAMS_KEYS = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "utm_adgroup",
    "utm_device",
    "utm_campaignid",
    "utm_adgroupid",
    "gclid",
    "fbclid",
  ];

  protected data: UtmDto = {};

  constructor() {
    const urlPayload = this.loadFromUrl();
    if (
      // Check if object empty
      urlPayload &&
      Object.keys(urlPayload).length === 0
    ) {
      this.data = this.loadFromSession();
    } else {
      this.data = urlPayload;
      this.writeToSession();
    }
  }

  public getUtmParams(): UtmDto {
    return this.data;
  }

  protected loadFromUrl(): UtmDto {
    const urlParams = new URLSearchParams(window.location.search);
    const payload = {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_content: urlParams.get("utm_content"),
      utm_term: urlParams.get("utm_term"),
      utm_adgroup: urlParams.get("utm_adgroup"),
      utm_device: urlParams.get("utm_device"),
      utm_campaignid: urlParams.get("utm_campaignid"),
      utm_adgroupid: urlParams.get("utm_adgroupid"),
      gclid: urlParams.get("gclid"),
      fbclid: urlParams.get("fbclid"),
    };
    return this.filterUtmParams(payload);
  }

  protected loadFromSession(): UtmDto {
    let payload = {};
    try {
      payload = JSON.parse(sessionStorage.getItem(UtmService.SESSION_STORAGE_KEY)) ?? {};
    } catch (e) {
      payload = {};
    }
    return this.filterUtmParams(payload);
  }

  protected writeToSession(): void {
    sessionStorage.setItem(UtmService.SESSION_STORAGE_KEY, JSON.stringify(this.data));
  }

  /**
   * Removes all key-value pairs 1/ with non-string values, 2/ with keys not defined in UTM_PARAMS_KEYS
   */
  protected filterUtmParams(utmParams: any): UtmDto {
    Object.keys(utmParams).forEach((key) => {
      if (typeof utmParams[key] !== "string" || !UtmService.UTM_PARAMS_KEYS.includes(key)) {
        delete utmParams[key];
      }
    });
    return utmParams;
  }
}
