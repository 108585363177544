import type { StorageTagger } from "@/services/storageTagger.service";
import CreateCoverageQueryRequestBuilder from "@/services/webapi/builder/CreateCoverageQueryRequestBuilder";
import type CookieService from "@/services/cookies/CookieService";
import type LeadInfoService from "@/services/webapi/LeadInfoService";

export default class CreateCoverageQueryRequestBuilderFactory {
  // Services from DI
  protected leadInfoService: LeadInfoService;
  protected cookieService: CookieService;
  protected localStorageTagger: StorageTagger;
  protected sessionStorageTagger: StorageTagger;

  constructor(
    leadInfoService: LeadInfoService,
    cookieService: CookieService,
    localStorageTagger: StorageTagger,
    sessionStorageTagger: StorageTagger
  ) {
    this.leadInfoService = leadInfoService;
    this.cookieService = cookieService;
    this.localStorageTagger = localStorageTagger;
    this.sessionStorageTagger = sessionStorageTagger;
  }

  public create(): CreateCoverageQueryRequestBuilder {
    return new CreateCoverageQueryRequestBuilder(
      this.leadInfoService,
      this.cookieService,
      this.localStorageTagger,
      this.sessionStorageTagger
    );
  }
}
