import type { CreateWebOrderRequest } from "@/services/webapi/request/CreateWebOrderRequest";
import type { CreateCoverageQueryRequest } from "@/services/webapi/request/CreateCoverageQueryRequest";
import type { CreateWebOrderResponse } from "@/services/webapi/request/CreateWebOrderResponse";

export default class WebApiService {
  protected webApiUrl = "";
  constructor(webApiUrl: string) {
    this.webApiUrl = webApiUrl;
  }

  protected static getHeaders(): Headers {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("x-dream-extauth-token", "VUq1K6tSDnkTjPi8Wb4Mlf5QzOcdZF");
    return headers;
  }

  async createWebOrder(createWebOrderRequest: CreateWebOrderRequest): Promise<CreateWebOrderResponse> {
    const method = "POST";
    const url = this.webApiUrl + "/order";

    const request = new Request(url, {
      method: method,
      headers: WebApiService.getHeaders(),
      body: JSON.stringify(createWebOrderRequest),
    });
    const response = await fetch(request);
    if (!response.ok) {
      throw new Error(`Web order creation failed with code ${response.status}`);
    }
    return await response.json();
  }

  /**
   * This method logs the coverage query into WebApi - it is not meant to execute the query itsef.
   * @param createCoverageQueryRequest
   */
  async createCoverageQuery(createCoverageQueryRequest: CreateCoverageQueryRequest): Promise<void> {
    const method = "POST";
    const url = this.webApiUrl + "/coverage_query";

    const request = new Request(url, {
      method: method,
      headers: WebApiService.getHeaders(),
      body: JSON.stringify(createCoverageQueryRequest),
    });

    await fetch(request);
  }
}
