export class StorageTagger {
  protected storage: Storage = null;
  protected key: string = null;

  constructor(storage: Storage, key: string) {
    this.storage = storage;
    this.key = key;

    if (this.getTag() === null) {
      this.writeToStorage(this.generateUserId());
    }
  }

  public getTag() {
    return this.storage.getItem(this.key);
  }

  protected writeToStorage(value: string) {
    this.storage.setItem(this.key, value);
  }

  protected generateUserId(length = 15) {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
