import type Address from "@/services/address/Address";
import type { CreateCoverageQueryRequest } from "@/services/webapi/request/CreateCoverageQueryRequest";
import type { CoverageQueryResponse } from "@/services/coverage/CoverageQueryResponse";
import AbstractRequestBuilder from "@/services/webapi/builder/AbstractRequestBuilder";

/**
 * Provides builder iface to create a CreateCoverageQueryRequest instance.
 */
export default class CreateCoverageQueryRequestBuilder extends AbstractRequestBuilder {
  // Own
  protected address: Address | null = null;
  protected response: CoverageQueryResponse | null = null;
  protected hasCoverage: boolean | null = null;
  protected hasPlannedCoverage: boolean | null = null;
  protected captcha: string | null = null;

  public setAddress(address?: Address): this {
    this.address = address;
    return this;
  }

  public setResponse(response?: CoverageQueryResponse): this {
    this.response = response;
    return this;
  }

  public setHasCoverage(hasCoverage?: boolean): this {
    this.hasCoverage = hasCoverage;
    return this;
  }

  public setHasPlannedCoverage(hasPlannedCoverage?: boolean): this {
    this.hasPlannedCoverage = hasPlannedCoverage;
    return this;
  }

  public setCaptcha(captcha?: string): this {
    this.captcha = captcha;
    return this;
  }

  protected getCurrentTariffTechnology(): string | null {
    if (!this.getCurrentHasCoverage()) {
      return null;
    }
    // Technology of the 1st returned tariff in coverage query response
    return this.response.result[0]?.param_pvr;
  }

  protected getCurrentHasCoverage(): boolean | null {
    if (this.response === null) return null;
    if (this.response.result.length === 0) return false;
    return true;
  }

  public getRequest(): CreateCoverageQueryRequest {
    const podaAuthTrack = this.cookieService.getCookieOne("poda_auth_track");
    const isEmployee = podaAuthTrack !== "" && podaAuthTrack !== null;

    const request = {} as CreateCoverageQueryRequest;
    request.addr_idadr = this.address.idadr.toString();
    request.addr_name = this.address.address_full;
    request.address_zip_code = this.address.psc;
    request.addr_info = this.address;
    request.has_coverage = this.hasCoverage;
    request.has_planned_coverage = this.hasPlannedCoverage;
    request.coverage_technology = this.getCurrentTariffTechnology();

    request.is_employee = isEmployee;
    request.persistent_storage_id = this.localStorageTagger.getTag();
    request.session_storage_id = this.sessionStorageTagger.getTag();
    request.lead_info = this.leadInfoService.getLeadInfo();
    if (this.captcha !== null) {
      request.captcha = this.captcha;
    }

    return request;
  }
}
