import * as Sentry from "@sentry/vue";
import type { ExponeaService } from "./tracking/exponea.service";
import type GTMService from "./tracking/gtm.service";

interface Visitor {
  isCustomer: boolean | null;
  checkedAt: string | null;
}

export class CustomerService {
  protected gtmService: GTMService;
  protected exponeaService: ExponeaService;

  protected url = "/wp-json/visitor/is-customer";
  protected isCustomerKey = "visitor.isC";
  protected checkedAtKey = "visitor.checkedAt";
  protected checkedThisSessionKey = "visitor.checkedThisSession";

  protected activeRequests = new Set();
  protected requestPromises = new Map();
  protected requestCache = new Map();

  protected visitorIsCustomer: string | null;
  protected visitorCheckedAt: string | null;
  protected checkedThisSession: string | null;

  constructor(gtmService: GTMService, exponeaService: ExponeaService) {
    this.visitorIsCustomer = localStorage.getItem(this.isCustomerKey);
    this.visitorCheckedAt = localStorage.getItem(this.checkedAtKey);
    this.checkedThisSession = sessionStorage.getItem(this.checkedThisSessionKey);

    this.gtmService = gtmService;
    this.exponeaService = exponeaService;
  }

  public async getIsCustomer(): Promise<Visitor> {
    if (
      !this.visitorIsCustomer ||
      !this.visitorCheckedAt ||
      (this.visitorIsCustomer === "false" && !this.checkedThisSession)
    ) {
      try {
        await this.request();
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    return {
      isCustomer: this.visitorIsCustomer === "true",
      checkedAt: this.visitorCheckedAt,
    };
  }

  protected async request(): Promise<Response> {
    if (this.requestCache.has(this.url)) {
      return Promise.resolve(this.requestCache.get(this.url));
    }

    if (this.activeRequests.has(this.url)) {
      return this.requestPromises.get(this.url);
    }

    this.activeRequests.add(this.url);

    const requestPromise = fetch(this.url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const currentDate = Date.now().toString();
        this.visitorIsCustomer = data.is_customer;
        this.visitorCheckedAt = currentDate;
        this.checkedThisSession = "true";

        localStorage.setItem(this.isCustomerKey, this.visitorIsCustomer);
        localStorage.setItem(this.checkedAtKey, this.visitorCheckedAt);
        sessionStorage.setItem(this.checkedThisSessionKey, this.checkedThisSession);

        setTimeout(() => {
          if (data.is_customer) {
            this.requestPromises.set("gtm", this.gtmService.trackOncePerSession("identified_as_customer", {}));
            this.requestPromises.set("exponea", this.exponeaService.trackOncePerSession("identified_as_customer", {}));
          }
        }, 50);

        this.requestCache.set(this.url, data);
        this.activeRequests.delete(this.url);
        this.requestPromises.delete(this.url);
        return data;
      })
      .catch((error: Error) => {
        console.error("Error occurred: ", error);
        Sentry.captureException(error);
        this.activeRequests.delete(this.url);
        this.requestPromises.delete(this.url);
        throw error;
      });

    this.requestPromises.set(this.url, requestPromise);

    return requestPromise;
  }
}
