import type { UtmService } from "@/services/utm.service";
import type { StorageTagger } from "@/services/storageTagger.service";
import CreateWebOrderRequestBuilder from "@/services/webapi/builder/CreateWebOrderRequestBuilder";
import type CookieService from "@/services/cookies/CookieService";
import type LeadInfoService from "@/services/webapi/LeadInfoService";

export default class CreateWebOrderRequestBuilderFactory {
  protected leadInfoService: LeadInfoService;
  protected cookieService: CookieService;
  protected localStorageTagger: StorageTagger;
  protected sessionStorageTagger: StorageTagger;

  constructor(
    leadInfoService: LeadInfoService,
    cookieService: CookieService,
    localStorageTagger: StorageTagger,
    sessionStorageTagger: StorageTagger
  ) {
    this.leadInfoService = leadInfoService;
    this.cookieService = cookieService;
    this.localStorageTagger = localStorageTagger;
    this.sessionStorageTagger = sessionStorageTagger;
  }

  public create(): CreateWebOrderRequestBuilder {
    return new CreateWebOrderRequestBuilder(
      this.leadInfoService,
      this.cookieService,
      this.localStorageTagger,
      this.sessionStorageTagger
    );
  }
}
