import type { CoverageQueryResponse } from "@/services/coverage/CoverageQueryResponse";
import type WebApiService from "@/services/webapi/WebApiService";
import type Address from "@/services/address/Address";
import type GTMService from "@/services/tracking/gtm.service";
import type CreateCoverageQueryRequestBuilderFactory from "@/services/webapi/factory/CreateCoverageQueryRequestBuilderFactory";
import type RecaptchaService from "@/services/recaptcha.service";
import type { ExponeaService } from "@/services/tracking/exponea.service";

const CACHED_RESPONSE_KEY = "CoverageService.cachedResponse";

export default class CoverageService {
  // DI
  protected webApiService: WebApiService;
  protected gtmService: GTMService;
  protected exponeaService: ExponeaService;
  protected coverageQueryRequestBuilderFactory: CreateCoverageQueryRequestBuilderFactory;
  protected recaptchaService: RecaptchaService;
  // Own
  protected coverageApiUrl: string;
  protected cachedResponse: CoverageQueryResponse | null = null;

  constructor(
    webApiService: WebApiService,
    gtmService: GTMService,
    exponeaService: ExponeaService,
    coverageQueryRequestBuilderFactory: CreateCoverageQueryRequestBuilderFactory,
    recaptchaService: RecaptchaService,
    coverageApiUrl: string
  ) {
    this.webApiService = webApiService;
    this.gtmService = gtmService;
    this.exponeaService = exponeaService;
    this.coverageQueryRequestBuilderFactory = coverageQueryRequestBuilderFactory;
    this.recaptchaService = recaptchaService;
    this.coverageApiUrl = coverageApiUrl;
  }

  protected getCachedResponse(): CoverageQueryResponse | null {
    if (!window.sessionStorage) {
      return null;
    }
    return JSON.parse(window.sessionStorage.getItem(CACHED_RESPONSE_KEY));
  }

  protected setCachedResponse(response: CoverageQueryResponse | null): void {
    if (!window.sessionStorage) {
      return;
    }
    window.sessionStorage.setItem(CACHED_RESPONSE_KEY, JSON.stringify(response));
  }

  /**
   * Sets provided address as current address and returns a list of available tariffs - fetched if not cached.
   */
  public async setAddressAndGetTariffs(address: Address): Promise<CoverageQueryResponse> {
    const coverageResponse = await this.getTariffsForAddrId(address.idadr);
    this.setCachedResponse(coverageResponse);
    // Track to webapi
    const coverageQueryReqBuilder = this.coverageQueryRequestBuilderFactory.create();
    coverageQueryReqBuilder.setAddress(address);
    coverageQueryReqBuilder.setResponse(coverageResponse);
    coverageQueryReqBuilder.setHasCoverage(await this.getHasCoverage());
    coverageQueryReqBuilder.setHasPlannedCoverage(await this.getHasPlannedCoverage());
    coverageQueryReqBuilder.setCaptcha(await this.recaptchaService.getToken("coverage_query"));
    const coverageQueryReq = coverageQueryReqBuilder.getRequest();

    await this.webApiService.createCoverageQuery(coverageQueryReq);
    // Track to GTM (if not tracked before), recycle data from webapi req
    const gtmReq = {
      value: coverageQueryReq.has_coverage,
      has_coverage: coverageQueryReq.has_coverage,
      idadr: coverageQueryReq.addr_idadr,
      city: address.mesto,
      technology: null as string | null,
    };
    if (coverageQueryReq.has_coverage) {
      gtmReq.technology = coverageQueryReq.coverage_technology;
    }
    await Promise.all([
      this.gtmService.trackCoverageQuery(coverageQueryReq.addr_idadr, gtmReq),
      this.exponeaService.trackCoverageQuery(coverageQueryReq.addr_idadr, gtmReq),
    ]);
    return coverageResponse;
  }

  public async getHasCoverage(): Promise<boolean | null> {
    const cachedResponse = this.getCachedResponse();
    if (cachedResponse === null) return null;
    if (cachedResponse?.result === undefined) return null;
    return cachedResponse?.result?.length !== null && cachedResponse.result.length !== 0;
  }

  /**
   * @returns boolean|null: At least one of the available tariffs has coverage in "planned" state, null if no tariffs
   * available
   */
  public async getHasPlannedCoverage(): Promise<boolean | null> {
    const cachedResponse = this.getCachedResponse();
    if (cachedResponse === null) return null;
    if (cachedResponse.result.length === 0) return false;
    for (const tariff of cachedResponse.result) {
      if (
        (tariff.stav_doba !== undefined && tariff.stav_doba !== null) ||
        (tariff.stav_termin !== undefined && tariff.stav_termin !== null)
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * Loads tariffs with given addressId from the server
   * @param addressId
   * @protected
   */
  protected async getTariffsForAddrId(addressId: number): Promise<CoverageQueryResponse> {
    const url =
      this.coverageApiUrl +
      "?" +
      new URLSearchParams({
        id_adr: addressId.toString(),
      });
    const options = {
      method: "GET",
      headers: {
        "x-dream-extauth-token": "VUq1K6tSDnkTjPi8Wb4Mlf5QzOcdZF",
        "Content-Type": "application/json",
      },
    };
    const tariffs = await fetch(url, options);
    return {
      status: true,
      result: await tariffs.json(),
    };
  }
}
