import type CookieService from "@/services/cookies/CookieService";

const EHUB_TOKEN_COOKIE_NAME = "ehub_token";
const EHUB_TOKEN_URL_PARAM_NAME = "ehub";
const EHUB_ATTRIB_WIN_DAYS = 30;

export type EhubDto = {
  ehub?: string;
};

export default class EhubService {
  protected cookieService: CookieService;

  constructor(cookieService: CookieService) {
    this.cookieService = cookieService;
  }

  /**
   * Checks for "ehub" token in the URL and saves it to cookies for now+30 days if "analytical" consent was granted.
   * Overwrites are permitted.
   */
  public initialize(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get(EHUB_TOKEN_URL_PARAM_NAME);
    if (tokenFromUrl === null) {
      return;
    }
    // Check for cookie consent
    const consentCookie = this.cookieService.getCookieOne("consent");
    let consentCookieJson;
    try {
      consentCookieJson = JSON.parse(consentCookie);
    } catch (e) {
      console.log(e);
    }
    if (consentCookieJson && consentCookieJson.level && consentCookieJson.level.includes("analytics")) {
      // Set ehub token to cookie
      this.cookieService.setCookie(EHUB_TOKEN_COOKIE_NAME, tokenFromUrl, EHUB_ATTRIB_WIN_DAYS);
      return;
    }
    // Set ehub token to session storage
    if (window.sessionStorage) {
      sessionStorage.setItem(EHUB_TOKEN_COOKIE_NAME, tokenFromUrl);
    }
  }

  /**
   * Gets ehub token from:
   * 1. URL
   * 2. cookie if present and "analytical" consent was given
   * 3. session storage
   * 4. null otherwise
   */
  public getEhubToken(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    // 1. Url
    const tokenFromUrl = urlParams.get(EHUB_TOKEN_URL_PARAM_NAME);
    if (tokenFromUrl !== null) {
      return tokenFromUrl;
    }
    // 2. Cookie
    const tokenFromCookie = this.cookieService.getCookieOne(EHUB_TOKEN_COOKIE_NAME);
    if (tokenFromCookie !== "") {
      return tokenFromCookie;
    }
    // 3. Session storage
    if (window.sessionStorage) {
      return sessionStorage.getItem(EHUB_TOKEN_COOKIE_NAME);
    }
    return null;
  }
}
