import { Subject } from "rxjs";
import { MainModelService } from "@/services/main.model.service";
import Address from "@/services/address/Address";

export default class AddressService extends MainModelService {
  addressSubject = new Subject<Address>();
  editMode = false;
  activeAddressId = "";
  address?: Address = null;
  editAddress?: Address = new Address(); // do not change to null, see content-address-form.php L28
  loading = false;
  addressSuggests: Address[] = null;
  addressInputSubject = new Subject<{ id: string; value: string }>();
  addressSearchController: AbortController = null;
  protected url: string;

  constructor(url: string) {
    super();
    this.url = url;
    this.getAddressFromStorage();
  }

  hasAddress() {
    return !!this.address;
  }

  getAddressFromStorage() {
    const address = sessionStorage.getItem("poda_address");
    if (address) {
      this.address = JSON.parse(address);
      this.editAddress = { ...this.address };
    } else {
      this.editMode = true;
      this.editAddress = new Address();
    }
  }

  removeAddressFromStorage() {
    localStorage.removeItem("poda_address");
  }

  saveAddressToStorage() {
    if (this.address) {
      sessionStorage.setItem("poda_address", JSON.stringify(this.address));
    }
  }

  changeAddressId(id: string) {
    this.activeAddressId = id;
  }

  clearAddress() {
    this.editAddress = new Address();
    this.address = new Address();
    this.removeAddressFromStorage();
  }

  toggleEditMode(value: boolean) {
    this.editAddress = { ...this.address };
    this.editMode = value;
  }

  saveAddress(id: string) {
    if (this.editAddress?.idadr) {
      this.address = { ...this.editAddress };
      this.addressSubject.next(this.address);
      this.saveAddressToStorage();
      this.editMode = false;
      const input = document.getElementById(id) as HTMLInputElement;
      const url = input?.getAttribute("data-redirect-url");
      if (url) {
        document.location.href = url;
      }
    } else {
      document.getElementById(id)?.focus();
    }
  }

  addressInput(id: string) {
    const value = (document.getElementById(id) as HTMLInputElement).value;
    this.editAddress.idadr = null;
    this.addressSuggests = null;

    if (value.length <= 3) {
      return;
    }

    this.loading = true;
    if (this.addressSearchController) {
      this.addressSearchController.abort();
    }
    this.addressSearchController = this.postRequest(
      this.url,
      { search_text: value, max_hits: 10 },
      {},
      (response) => {
        this.addressSuggests = response.hits;
        this.loading = false;
      },
      {
        headers: {
          //'Content-Type': 'application/json',
          "x-dream-extauth-token": "VUq1K6tSDnkTjPi8Wb4Mlf5QzOcdZF",
        },
      }
    );
  }

  selectAddressForSuggest(item: Address, id: string) {
    this.editAddress = item;
    this.editAddress.address_full =
      this.editAddress.address_line_1 + " " + this.editAddress.mesto + " , " + this.editAddress.psc;
    this.addressSuggests = null;
    this.saveAddress(id);
  }

  closeSuggests(id: string) {
    if (this.activeAddressId === id) {
      this.activeAddressId = "";
    }
  }
}
