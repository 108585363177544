import type { Tariff } from "@/services/coverage/Tariff";
import type { CreateWebOrderRequest } from "@/services/webapi/request/CreateWebOrderRequest";
import type Address from "@/services/address/Address";
import AbstractRequestBuilder from "@/services/webapi/builder/AbstractRequestBuilder";

/**
 * Provides builder iface to create a CreateWebOrderRequest instance.
 */
export default class CreateWebOrderRequestBuilder extends AbstractRequestBuilder {
  // Own
  protected tariff: Tariff | null = null;
  protected technology: string | null = null;
  protected phone: string | null = null;
  protected address: Address | null = null;
  protected hasCoverage: boolean | null = null;
  protected hasPlannedCoverage: boolean | null = null;
  protected recaptcha: string | null = null;
  protected referringCustomerId: string | null = null;
  protected orderSource: string | null = null;
  protected customerType: string | null = null;

  public setPhone(phone?: string): this {
    this.phone = phone;
    return this;
  }

  public setTariff(tariff?: Tariff): this {
    this.tariff = tariff;
    return this;
  }

  public setTechnology(technology?: string): this {
    this.technology = technology;
    return this;
  }

  public setCustomerType(customerType?: string): this {
    this.customerType = customerType;
    return this;
  }

  public setAddress(address?: Address): this {
    this.address = address;
    return this;
  }

  public setHasCoverage(hasCoverage?: boolean): this {
    this.hasCoverage = hasCoverage;
    return this;
  }

  public setHasPlannedCoverage(hasPlannedCoverage?: boolean): this {
    this.hasPlannedCoverage = hasPlannedCoverage;
    return this;
  }

  public setRecaptcha(recaptcha?: string): this {
    this.recaptcha = recaptcha;
    return this;
  }

  public setReferringCustomerId(value: string | null): this {
    this.referringCustomerId = value;
    return this;
  }

  public setOrderSource(value: string | null): this {
    this.orderSource = value;
    return;
  }

  public async getRequest(): Promise<CreateWebOrderRequest> {
    const podaAuthTrack = this.cookieService.getCookieOne("poda_auth_track");
    const isEmployee = podaAuthTrack !== "" && podaAuthTrack !== null;
    const customerId = parseInt(this.cookieService.getCookieOne("poda_id")) ?? null;

    const request = {} as CreateWebOrderRequest;
    if (this.phone !== null) {
      request.tel_num = this.phone;
    }
    if (this.address !== null) {
      request.addr_idadr = this.address.idadr;
      request.addr_name = this.address.address_full;
      request.addr_info = this.address;
      request.address_zip_code = this.address.psc;
    }
    if (this.tariff !== null) {
      request.tariff_id = this.tariff?.tarif_id;
      request.tariff_name = this.tariff?.tarif_nazev;
      request.tariff_technology = this.tariff?.param_pvr;
    }
    if (this.technology !== null) {
      request.tariff_technology = this.technology;
    }
    if (this.recaptcha !== null) {
      request.captcha = this.recaptcha;
    }
    if (this.orderSource !== null) {
      request.order_source = this.orderSource;
    } else {
      request.order_source = "web";
    }
    if (this.customerType !== null) {
      request.customer_type = this.customerType;
    }
    request.captcha_score = "1"; // TODO Remove
    request.lead_customer_id = customerId;
    request.is_employee = isEmployee;
    request.persistent_storage_id = this.localStorageTagger.getTag();
    request.session_storage_id = this.sessionStorageTagger.getTag();
    request.lead_info = this.leadInfoService.getLeadInfo();
    request.has_coverage = this.hasCoverage;
    request.has_planned_coverage = this.hasPlannedCoverage;
    if (this.referringCustomerId) {
      const numericId = parseInt(this.referringCustomerId);
      request.referring_customer_id = isNaN(numericId) ? null : numericId;
    }
    request.order_source = this.orderSource ?? "web";
    return request;
  }
}
