import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { reactive } from "vue";

export class MainModelService {
  public postRequest(
    url: string,
    data: any = null,
    getData: any = null,
    callback: (response: any) => void,
    config: AxiosRequestConfig = null,
    callbackError: (error: any) => void = null
  ) {
    if (!config) {
      config = {};
    }
    if (getData) {
      config = { ...config, params: getData };
    }
    const controller = new AbortController();
    config.signal = controller.signal;

    axios
      .post(url, data, config)
      .then((response) => {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
        callbackError?.(error);
      });
    return controller;
  }

  public putRequest(
    url: string,
    data: any = null,
    getData: any = null,
    callback: (response: any) => void,
    config: AxiosRequestConfig = null
  ) {
    if (!config) {
      config = {};
    }
    if (getData) {
      config = { ...config, params: getData };
    }
    const controller = new AbortController();
    config.signal = controller.signal;

    axios
      .put(url, data, config)
      .then((response) => {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    return controller;
  }

  public getRequest(
    url: string,
    data: any = null,
    callback: (response: AxiosResponse<any>) => void,
    config: AxiosRequestConfig = null
  ) {
    if (!config) {
      config = {};
    }
    if (data) {
      config = { ...config, params: data };
    }
    const controller = new AbortController();
    config.signal = controller.signal;
    axios
      .get(url, config)
      .then((response) => {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    return controller;
  }

  public deleteRequest(
    url: string,
    data: any = null,
    callback: (response: AxiosResponse<any>) => void,
    config: AxiosRequestConfig = null
  ) {
    if (!config) {
      config = {};
    }
    if (data) {
      config = { ...config, params: data };
    }
    const controller = new AbortController();
    config.signal = controller.signal;
    axios
      .delete(url, config)
      .then((response) => {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    return controller;
  }

  searchRequestCall(
    model: string,
    query: {
      searchQuery: string;
      department?: string;
      location?: string;
      postId?: any;
    },
    callback: (items: any) => void
  ): any {
    return this.postRequest(`/wp-json/${model}/query/`, query, null, (response: any) => {
      callback(response);
    });
  }
}

export const mainModelService = reactive(new MainModelService());
