export default class Address {
  address_full: string;
  address_line_1: string;
  address_line_2: string;
  cast_obce: string;
  cislo_evidencni: string;
  cislo_orientacni: string;
  cislo_popisne: string;
  idadr: number;
  location: {
    coordinates: number[];
    type: string;
  };
  mesto: string;
  mestska_cast: string;
  psc: string;
  ulice: string;
}
