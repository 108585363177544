import type { ClientContactFormRequest } from "@/services/wordpress/ClientContactFormRequest";
import type { ContactFormResponse } from "@/services/wordpress/ContactFormResponse";
import type { BusinessContactFormRequest } from "@/services/wordpress/BusinessContactFormRequest";
import type { IptvEmailFormRequest } from "@/services/wordpress/IptvEmailFormRequest";
import type { ReferralReportFormRequest } from "@/services/wordpress/ReferralReportFormRequest";
import type { IptvStep2FormRequest } from "./IptvStep2FormRequest";
import type { IptvStep2FormResponse } from "./IptvStep2FormResponse";

export default class ContactFormService {
  async submitClientContactForm(body: ClientContactFormRequest): Promise<ContactFormResponse> {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch("/wp-json/form/contact_us/", options);
    return await res.json();
  }

  async submitBusinessContactForm(body: BusinessContactFormRequest): Promise<ContactFormResponse> {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch("/wp-json/form/contact_us_business/", options);
    return await res.json();
  }

  async submitReferralReportForm(body: ReferralReportFormRequest): Promise<ContactFormResponse> {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch("/wp-json/form/referral-report/", options);
    return await res.json();
  }

  async submitGenericForm(body: any): Promise<ContactFormResponse> {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch("/wp-json/form/generic_form/", options);
    return await res.json();
  }

  async submitIptvEmailForm(body: IptvEmailFormRequest): Promise<ContactFormResponse> {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch("/wp-json/form/iptv-email/", options);
    return await res.json();
  }

  async submitIptvStep2Form(body: IptvStep2FormRequest): Promise<IptvStep2FormResponse> {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    const res = await fetch("/wp-json/form/iptv-step-2/", options);
    return await res.json();
  }
}
