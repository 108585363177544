import type { UtmDto } from "@/services/utm.service";
import type { EhubDto } from "@/services/ehub/EhubService";
import type { UtmService } from "@/services/utm.service";
import type EhubService from "@/services/ehub/EhubService";

export default class LeadInfoService {
  protected utmService: UtmService;
  protected ehubService: EhubService;

  constructor(utmService: UtmService, ehubService: EhubService) {
    this.utmService = utmService;
    this.ehubService = ehubService;
  }

  public getLeadInfo(): UtmDto & EhubDto {
    const res = this.utmService.getUtmParams() as UtmDto & EhubDto;

    const ehubToken = this.ehubService.getEhubToken();
    if (ehubToken !== null) {
      res.ehub = ehubToken;
    }

    return res;
  }
}
